import moment from 'moment';

export default function highlightOverdue(item: any): string {
  if (!item) {
    return ''
  }
  const itemExpiration = item.due_date || item.dueDate
  if (item !== null && item.status !== 'paid' && moment(itemExpiration).isBefore(moment())) {
    return item.status === 'partially-paid' ? 'partially-overdue' : 'overdue'
  }
  return ''
}
