var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.request)?_c('FormTemplate',{attrs:{"error":_vm.error,"hide-buttons":true},on:{"submit":_vm.submitDialog},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('SearchableField',{attrs:{"eager-loading":true,"id":"status","title":_vm.__('views.requests.form.status'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half,"loadItemsProps":{
          endpoint: 'requests/statuses',
          value: 'key',
          title: 'value'
        }},model:{value:(_vm.request.status),callback:function ($$v) {_vm.$set(_vm.request, "status", $$v)},expression:"request.status"}}),_c('SearchableField',{attrs:{"eager-loading":true,"id":"source","title":_vm.__('views.requests.form.source'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half,"loadItemsProps":{
          endpoint: 'requests/sources',
          value: 'key',
          title: 'value'
        }},model:{value:(_vm.request.source),callback:function ($$v) {_vm.$set(_vm.request, "source", $$v)},expression:"request.source"}}),_c('SearchableField',{attrs:{"id":"client_id","title":_vm.__('views.requests.form.current-client'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half,"loadItemsProps":{
        endpoint: 'clients',
         value: 'id',
        title: 'company_name'
        }},on:{"input":_vm.onClientSelect},model:{value:(_vm.request.client_id),callback:function ($$v) {_vm.$set(_vm.request, "client_id", $$v)},expression:"request.client_id"}}),_c('SearchableField',{attrs:{"id":"manager_id","title":_vm.__('views.requests.form.manager'),"size":_vm.FieldSizes.half,"props":props,"loadItemsProps":{
          plainFilter: '&filter[equals.disabled]=0',
          endpoint: 'users',
          value: 'id',
          title: 'full_name'
        }},model:{value:(_vm.request.manager_id),callback:function ($$v) {_vm.$set(_vm.request, "manager_id", $$v)},expression:"request.manager_id"}}),_c('TextField',{attrs:{"id":"phone","title":_vm.__('views.requests.form.phone'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.request.phone),callback:function ($$v) {_vm.$set(_vm.request, "phone", $$v)},expression:"request.phone"}}),_c('TextField',{attrs:{"id":"email","title":_vm.__('views.requests.form.email'),"type":"email","required-decoration":true,"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.request.email),callback:function ($$v) {_vm.$set(_vm.request, "email", $$v)},expression:"request.email"}}),_c('SearchableField',{attrs:{"id":"offer_id","title":_vm.__('views.requests.form.offer'),"props":props,"loadItemsProps":{
          endpoint: 'offers',
          value: 'id',
          title: 'title'
        }},model:{value:(_vm.request.offer_id),callback:function ($$v) {_vm.$set(_vm.request, "offer_id", $$v)},expression:"request.offer_id"}}),_c('RichEditorField',{attrs:{"id":"description","title":_vm.__('views.requests.form.description'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.full},model:{value:(_vm.request.description),callback:function ($$v) {_vm.$set(_vm.request, "description", $$v)},expression:"request.description"}}),_c('div',{staticClass:"col-12 col-lg-6"},[_c('CheckboxField',{attrs:{"id":"send_reminder","title":_vm.__('views.requests.form.send_reminder'),"props":props,"checkedValue":1,"uncheckedValue":0},model:{value:(_vm.request.send_reminder),callback:function ($$v) {_vm.$set(_vm.request, "send_reminder", $$v)},expression:"request.send_reminder"}})],1),(_vm.request.send_reminder)?_c('DateField',{attrs:{"id":"reminder_date","title":_vm.__('views.requests.form.reminder_date'),"props":props,"size":_vm.FieldSizes.full},model:{value:(_vm.request.reminder_date),callback:function ($$v) {_vm.$set(_vm.request, "reminder_date", $$v)},expression:"request.reminder_date"}}):_vm._e()]}}],null,false,1005961071)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }