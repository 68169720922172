import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import SearchableField from '@/shared/classes/components/form/fields/searchable-field'
import __ from '@/helpers/__'
import DeleteAction from '../classes/components/form/fields/actions/delete-action-field'
import _ from 'lodash'
import FormBase from '../classes/components/form/form-base'
import { FieldTypes } from '../components/form/field-types'
import { InvoiceTypes } from './invoice/invoice.config'
import { VatGetters } from '@/store/modules/vat/vat.getters'
import { UnitGetters } from '@/store/modules/unit/unit.getters'

export const heading = [
  new Field()
    .setKey('title')
    .setArrayKey('title')
    .setRequiredDecoration(true)
    .setTitle(__('views.offers.form.heading.title'))
    .setSize(FieldSizes.elevenTwelfth)
    .setClasses('has-separator'),
  new DeleteAction()
    .setAction(removeField)
    .setSize(FieldSizes.oneTwelfthSmall)
    .setVisibleIf((data: any, index: any, group: any) => group.repeating)
]

export const prepaidService = [
  new SearchableField()
    .setArrayKey('title')
    .setDisplayKey('title')
    .setSelectKey('label')
    .setValueKey('title')
    .setNoUuid(true)
    .setTitle(__('views.invoices.form.name'))
    .setSize(FieldSizes.half)
    .setDataOnChange(setPrepaidInvoiceInfo)
    .setRequiredDecoration(true)
    .setAllowToCreate(true)
    .setType(FieldTypes.customDropdown)
    .loadItems({
      endpoint: '/services',
      value: 'id',
      title: 'title'
    }),
  new Field()
    .setArrayKey('price_per_unit')
    .setEntryKey('pricePerUnit')
    .setTitle(__('views.invoices.form.price'))
    .setRequiredDecoration(true)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setSize(FieldSizes.auto),
  new DeleteAction()
    .setAction(removeField)
    .setSize(FieldSizes.oneTwelfthSmall)
    .setVisibleIf((data: any, index: any, group: any) => group.repeating)
]

export const service = [
  new SearchableField()
    .setArrayKey('title')
    .setDisplayKey('title')
    .setValueKey('title')
    .setSelectKey('label')
    .setRequiredDecoration(true)
    .setNoUuid(true)
    .setTitle(__('views.invoices.form.name'))
    .setSize(FieldSizes.threeTwelfth)
    .setOnChange(setTotalWithVat)
    .setOnCreated(setTotalWithVat)
    .setDataOnChange(setInvoiceInfo)
    .setAllowToCreate(true)
    .setType(FieldTypes.customDropdown)
    .loadItems({
      endpoint: '/services',
      value: 'id',
      title: 'title'
    }),
  new SearchableField()
    .setArrayKey('vat')
    .setVuexGetter(VatGetters.getVats)
    .setRequiredDecoration(true)
    .setNoUuid(true)
    .setTitle(__('views.invoices.form.vats'))
    .setValueKey('percentage')
    .setOnChange(setTotalWithVat)
    .setSize(FieldSizes.oneTwelfth)
    .loadItems({
      endpoint: '/vats',
      value: 'id',
      title: 'title'
    }),
  new SearchableField()
    .setArrayKey('unit')
    .setVuexGetter(UnitGetters.getUnits)
    .setRequiredDecoration(true)
    .setNoUuid(true)
    .setVisibleIf((data: any) => data.type !== InvoiceTypes.prepaid)
    .setTitle(__('views.invoices.form.units'))
    .setValueKey('title')
    .setSize(FieldSizes.oneTwelfth)
    .loadItems({
      endpoint: '/units',
      value: 'id',
      title: 'title'
    }),
  new Field()
    .setArrayKey('amount')
    .setTitle(__('views.invoices.form.amount'))
    .setRequiredDecoration(true)
    .setOnChange(setTotalWithVat)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setSize(FieldSizes.oneTwelfth),
  new Field()
    .setArrayKey('price_per_unit')
    .setRequiredDecoration(true)
    .setEntryKey('pricePerUnit')
    .setTitle(__('views.invoices.form.price'))
    .setOnChange(setTotalWithVat)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setSize(FieldSizes.oneTwelfth),
  new Field()
    .setArrayKey('discount_percentage')
    .setEntryKey('discountPercentage')
    .setTitle(__('views.invoices.form.discount'))
    .setOnChange(setTotalWithVat)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setMaxValue(100)
    .setSize(FieldSizes.oneTwelfth),
  new Field()
    .setArrayKey('total_without_vat')
    .setEntryKey('totalWithoutVat')
    .setTitle(__('views.invoices.form.total'))
    .setSize('col-12 col-sm-6 col-lg')
    .setDisabled(true),
  new Field()
    .setArrayKey('total_with_vat')
    .setEntryKey('totalWithVat')
    .setTitle(__('views.invoices.form.total_with_vat'))
    .setSize('col-10 col-sm-5 col-lg')
    .setDisabled(true),
  new DeleteAction()
    .setAction(removeField)
    .setSize('col-2 col-sm-1 col-lg flex-lg-grow-0')
    .setVisibleIf((data: any, index: any, group: any) => group.repeating)
]

export const vatlessService = [
  new SearchableField()
    .setArrayKey('title')
    .setDisplayKey('title')
    .setValueKey('title')
    .setSelectKey('label')
    .setNoUuid(true)
    .setRequiredDecoration(true)
    .setTitle(__('views.invoices.form.name'))
    .setSize(FieldSizes.threeTwelfth)
    .setOnChange(setTotal)
    .setOnCreated(setTotal)
    .setDataOnChange(setInvoiceInfo)
    .setType(FieldTypes.customDropdown)
    .setAllowToCreate(true)
    .loadItems({
      endpoint: '/services',
      value: 'id',
      title: 'title'
    }),
  new SearchableField()
    .setVuexGetter(VatGetters.getVats)
    .setArrayKey('vat')
    .setRequiredDecoration(true)
    .setNoUuid(true)
    .setTitle(__('views.invoices.form.vats'))
    .setValueKey('percentage')
    .setOnChange(setTotal)
    .setSize(FieldSizes.twoTwelfthBig)
    .loadItems({
      endpoint: '/vats',
      value: 'id',
      title: 'title'
    }),
  new SearchableField()
    .setArrayKey('unit')
    .setVuexGetter(UnitGetters.getUnits)
    .setRequiredDecoration(true)
    .setNoUuid(true)
    .setVisibleIf((data: any) => data.type !== InvoiceTypes.prepaid)
    .setTitle(__('views.invoices.form.units'))
    .setValueKey('title')
    .setSize(FieldSizes.twoTwelfthBig)
    .loadItems({
      endpoint: '/units',
      value: 'id',
      title: 'title'
    }),
  new Field()
    .setArrayKey('amount')
    .setTitle(__('views.invoices.form.amount'))
    .setRequiredDecoration(true)
    .setOnChange(setTotal)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setSize(FieldSizes.oneTwelfth),
  new Field()
    .setArrayKey('price_per_unit')
    .setTitle(__('views.invoices.form.price'))
    .setOnChange(setTotal)
    .setRequiredDecoration(true)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setSize(FieldSizes.oneTwelfth),
  new Field()
    .setArrayKey('discount_percentage')
    .setTitle(__('views.invoices.form.discount'))
    .setOnChange(setTotal)
    .setType(FieldTypes.number)
    .setMinValue(0)
    .setMaxValue(100)
    .setSize(FieldSizes.oneTwelfth),
  new Field()
    .setArrayKey('total_with_vat')
    .setTitle(__('views.invoices.form.total'))
    .setSize(FieldSizes.oneTwelfthBig)
    .setDisabled(true),
  new DeleteAction()
    .setAction(removeField)
    .setSize(FieldSizes.oneTwelfthSmall)
    .setVisibleIf((data: any, index: any, group: any) => group.repeating)
]

function removeField(field?: any, group?: any, form?: any, rowIndex?: number) {
  group.removeFields(rowIndex)
  form.setData({
    ...form.data,
    [group.key]: _.omit(form.data[group.key], [field.index])
  })
  if (group.groupLocalData[field.index]) {
    group.setGroupLocalData({
      ...group.groupLocalData,
      [field.index]: {
        total: 0,
        totalWithVat: 0,
        vat: 0
      }
    })
  }
}

function setInvoiceInfo(form: FormBase, index: any, sourceData: any, group: any, loading?: boolean): any {
  if (!sourceData || !form?.data[group.key][index] || !group || loading) return false

  const { unit, amount, vat } = sourceData
  const pricePerUnit = sourceData.price_per_unit

  setData(form, index, group, {
    amount,
    unit,
    vat,
    price_per_unit: pricePerUnit
  })

  return true
}

function setPrepaidInvoiceInfo(form: FormBase, index: any, sourceData: any, group: any, loading?: boolean): any {
  if (!form?.data[group.key][index] || !group || loading) return false

  setData(form, index, group, {
    price_per_unit: sourceData.price_per_unit
  })

  return true
}

function setData(form: FormBase, index: any, group: any, data: any) {
  form.setData({
    ...form.data,
    [group.key]: {
      ...form.data[group.key],
      [index]: {
        ...form.data[group.key][index],
        ...data
      }
    }
  })
}

export function setTotalWithVat(form: any, index?: any, group?: any, rowIndex?: any) {
  const totalWithVatField: any = _.find(
    group.children[rowIndex],
    (item: any) => item.key === `${group.key}.${index}.total_with_vat`
  )
  const totalField: any = _.find(
    group.children[rowIndex],
    (item: any) => item.key === `${group.key}.${index}.total_without_vat`
  )
  const data = form.data[group.key]
  if (!data) return
  const { vat, amount } = data[index]
  const pricePerUnit = data[index].price_per_unit
  const discountPercentage = data[index].discount_percentage

  if (!vat && vat !== 0) {
    totalField.value = ''
    totalWithVatField.value = 'Pasirinkite PVM'
  }
  if (!amount && amount !== 0) {
    totalField.value = ''
    totalWithVatField.value = 'Įveskite kiekį'
  }
  if (!pricePerUnit && pricePerUnit !== 0) {
    totalField.value = ''
    totalWithVatField.value = 'Įveskite kainą'
  }

  const totalPrice = amount * pricePerUnit
  const actualVat = 1 + vat / 100
  const actualDiscount = 1 - (discountPercentage ? discountPercentage / 100 : 0)

  if ((vat || vat === 0) && (amount || amount === 0) && (pricePerUnit || pricePerUnit === 0)) {
    const totalWithVat: number = totalPrice * actualDiscount * actualVat
    const total: number = totalPrice * actualDiscount
    totalWithVatField.value = totalWithVat.toFixed(2)
    totalField.value = total.toFixed(2)
    group.setGroupLocalData({
      ...group.groupLocalData,
      [index]: {
        total,
        totalWithVat,
        vat: totalWithVat - total
      }
    })
  } else {
    group.setGroupLocalData({
      ...group.groupLocalData,
      [index]: {
        total: 0,
        totalWithVat: 0,
        vat: 0
      }
    })
  }
}

export function setTotal(form: any, index?: any, group?: any, rowIndex?: any) {
  const target: any = _.find(
    group.children[rowIndex],
    (item: any) => item.key === `${group.key}.${index}.total_with_vat`
  )
  const data = form.data[group.key]
  if (!data) return
  const { amount, vat } = data[index]
  const pricePerUnit = data[index].price_per_unit
  const discountPercentage = data[index].discount_percentage

  if (!pricePerUnit && pricePerUnit !== 0) target.value = 'Įveskite kainą'
  if (!vat && vat !== 0) target.value = 'Pasirinkite PVM'

  const totalPrice = (amount || 1) * pricePerUnit
  const actualDiscount = 1 - (discountPercentage ? discountPercentage / 100 : 0)
  const actualVat = 1 + vat / 100

  if ((vat || vat === 0) && (pricePerUnit || pricePerUnit === 0)) {
    const totalWithVat: number = totalPrice * actualDiscount * actualVat
    const total: number = totalPrice * actualDiscount
    target.value = total.toFixed(2)
    group.setGroupLocalData({
      ...group.groupLocalData,
      [index]: {
        total,
        totalWithVat,
        vat: totalWithVat - total
      }
    })
  } else {
    group.setGroupLocalData({
      ...group.groupLocalData,
      [index]: {
        total: 0,
        totalWithVat: 0,
        vat: 0
      }
    })
  }
}

export function changeData(data: any, form?: FormBase): any {
  const groupKey: string = 'information'
  const information: any = {}
  const target: any = _.find(form?.fields, (field: any) => field.key === groupKey)
  target.children.forEach((child: any, index: number) => {
    const fieldIndex = child[0].index
    information[index] = {
      ...data[groupKey][fieldIndex],
      position: index,
      discount_percentage: data[groupKey][fieldIndex].discount_percentage
        ? data[groupKey][fieldIndex].discount_percentage
        : 0
    }
  })
  return {
    ...data,
    information
  }
}
