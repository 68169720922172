var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[(_vm.form)?_c('FormTemplate',{attrs:{"error":_vm.error},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('SearchableField',{attrs:{"eager-loading":true,"id":"status","title":_vm.__('views.requests.form.status'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half,"loadItemsProps":{
            endpoint: 'requests/statuses',
            value: 'key',
            title: 'value'
          }},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('SearchableField',{attrs:{"eager-loading":true,"id":"source","title":_vm.__('views.requests.form.source'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half,"loadItemsProps":{
            endpoint: 'requests/sources',
            value: 'key',
            title: 'value'
          }},model:{value:(_vm.form.source),callback:function ($$v) {_vm.$set(_vm.form, "source", $$v)},expression:"form.source"}}),_c('SearchableField',{attrs:{"id":"client_id","title":_vm.__('views.requests.form.current-client'),"props":props,"size":_vm.FieldSizes.half,"required-decoration":true,"loadItemsProps":{
          endpoint: 'clients',
          value: 'id',
          title: 'company_name'
          }},on:{"input":_vm.onClientSelect},scopedSlots:_vm._u([{key:"notFoundComponent",fn:function(props){return [_c('ClientNotFound',{attrs:{"search-value":props.searchValue}})]}}],null,true),model:{value:(_vm.form.client_id),callback:function ($$v) {_vm.$set(_vm.form, "client_id", $$v)},expression:"form.client_id"}}),_c('SearchableField',{attrs:{"id":"manager_id","title":_vm.__('views.requests.form.manager'),"size":_vm.FieldSizes.half,"props":props,"loadItemsProps":{
              plainFilter: '&filter[equals.disabled]=0',
              endpoint: 'users',
              value: 'id',
              title: 'full_name'
          }},model:{value:(_vm.form.manager_id),callback:function ($$v) {_vm.$set(_vm.form, "manager_id", $$v)},expression:"form.manager_id"}}),_c('TextField',{attrs:{"id":"phone","title":_vm.__('views.requests.form.phone'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('TextField',{attrs:{"id":"email","title":_vm.__('views.requests.form.email'),"type":"email","required-decoration":true,"props":props,"size":_vm.FieldSizes.half},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('SearchableField',{attrs:{"id":"offer_id","title":_vm.__('views.requests.form.offer'),"props":props,"loadItemsProps":{
            endpoint: 'offers',
            value: 'id',
            title: 'title'
          }},model:{value:(_vm.form.offer_id),callback:function ($$v) {_vm.$set(_vm.form, "offer_id", $$v)},expression:"form.offer_id"}}),_c('RichEditorField',{attrs:{"id":"description","title":_vm.__('views.requests.form.description'),"required-decoration":true,"props":props,"size":_vm.FieldSizes.full},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"col-12 col-lg-6"},[_c('CheckboxField',{attrs:{"id":"send_reminder","title":_vm.__('views.requests.form.send_reminder'),"props":props,"size":null,"checkedValue":1,"uncheckedValue":0},model:{value:(_vm.form.send_reminder),callback:function ($$v) {_vm.$set(_vm.form, "send_reminder", $$v)},expression:"form.send_reminder"}})],1),(_vm.form.send_reminder)?_c('DateField',{attrs:{"id":"reminder_date","title":_vm.__('views.requests.form.reminder_date'),"props":props,"size":_vm.FieldSizes.full},model:{value:(_vm.form.reminder_date),callback:function ($$v) {_vm.$set(_vm.form, "reminder_date", $$v)},expression:"form.reminder_date"}}):_vm._e()]}}],null,false,3170795959)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }