













































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormField from '@/shared/components/form/FormField.vue'
import Field, { FieldSizes } from '@/shared/classes/components/form/field'
import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import _ from 'lodash'
import __ from '@/helpers/__'

@Component({
  components: {
    FormField
  },
  methods: { __ }
})
export default class Filters extends Vue {
  @Prop() filters!: DataTableFilter[]
  @Prop() loading!: boolean
  @Prop() table!: DataTableBase

  @Watch('filterQueries', { deep: true }) private onFilterChange(): void {
    this.debouncedSearch()
  }

  filtersEnabled: boolean = false
  private debouncedSearch = _.debounce(this.search, 400)

  searchField: Field = new Field()
    .setKey('q')
    .setShowClear(true)
    .setSize(FieldSizes.null)
    .setPlaceholder('Ieškoti...')

  created(): void {
    this.pushQueryToFilters()
    if (this.table.filterAlwaysOpen) {
      this.filtersEnabled = true
      return
    }
    if (this.query) this.filtersEnabled = true
    if (!_.isEmpty(this.$route.query)) {
      this.filtersEnabled = true
    }
  }

  get filteredFilters(): any {
    return this.filters.filter((filter: DataTableFilter) => filter.field)
  }

  get query(): string {
    if (!this.filtersEnabled) return ''
    let query = ''
    Object.keys(this.filterQueries).forEach((queryParam: any) => {
      query += this.filterQueries[queryParam] ? `&${queryParam}=${this.filterQueries[queryParam]}` : ''
    })
    return query
  }

  get filterQueries(): string[] {
    let filterObj: any = {}
    this.filteredFilters.forEach((filter: any) => {
      filterObj = {
        ...filterObj,
        ...filter.filterReturnParam(filter.field.value, filter.operator, filter.field)
      }
    })
    if (this.searchField.value) {
      filterObj = {
        ...filterObj,
        q: this.searchField.value
      }
    }
    return filterObj
  }

  search(): void {
    this.$router.replace({ query: Object.assign({ ...this.standardFilters, ...this.filterQueries }) })
  }

  clearFilter(): void {
    this.$router.replace({ query: { filter: 'clear' } })
    window.location.reload()
  }

  get standardFilters() {
    const filters: any = {}

    if (this.$route.query.page) filters.page = this.$route.query.page
    if (this.$route.query.sort) filters.sort = this.$route.query.sort

    return filters
  }

  pushQueryToFilters(): void {
    const query: any = this.$route.query

    Object.keys(query).forEach((key: string) => {
      if (key === 'q') this.searchField.value = query[key]
      if (key.startsWith('filter.')) {
        let fieldKey: string = key.replace(/filter./g, '')
        const operator = fieldKey.split('.')[0]
        fieldKey = fieldKey.substring(fieldKey.indexOf('.') + 1)
        const filter: DataTableFilter = _.find(
          this.filteredFilters,
          (filter: DataTableFilter) => filter.field.key === fieldKey
        )
        if (!filter) {
          return
        }
        filter.filterSetValue(filter.field, query[key], operator)
      }
    })
  }
}
