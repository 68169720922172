





























































































































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import IRequestPayload from '@/modules/requests/interfaces/request-payload.interface';
import { FieldSizes } from '@/shared/classes/components/form/field';
import _ from 'lodash';
import RequestService from '@/services/RequestService';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';
import User from '@/modules/user/models/user.model';
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import PageHeader from '@/components/layout/PageHeader.vue';
import { GeneralRoutes } from '@/router/routes/general';
import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import ClientService from '@/services/ClientService';

@Component({
  components: { PageHeader, ClientNotFound, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class RequestCreate extends Vue {
  FieldSizes = FieldSizes
  error: any = null
  user!: User

  private form: IRequestPayload | null = null

  created(): void {
    const clientId = parseInt(this.$route.params.clientId) || undefined
    this.form = {
      source: 'email',
      description: '',
      status: 'received',
      manager_id: this.user.id,
      phone: '',
      email: '',
      client_name: '',
      client_id: clientId,
      send_reminder: false,
      reminder_date: '',
      offer_id: undefined
    }
    if (clientId) {
      ClientService.getClientByID(clientId).then(response => {
        if (!response || !this.form) {
          return
        }

        this.form.phone = response.phone
        this.form.email = response.companyEmail
      })
    }
  }

  get payload(): IRequestPayload | null {
    if (this.form === null) {
      return null
    }
    const payload = _.cloneDeep(this.form)
    if (!payload.send_reminder) {
      payload.reminder_date = ''
    }
    if (payload.client_id) {
      delete payload.client_name
      return payload
    }
    delete payload.client_id
    return payload
  }

  private onClientSelect(clientId: string): void {
    http.get(`/clients/${clientId}`)
      .then((response: IResponse<IModelResponse>) => {
        if (this.form) {
          this.form.phone = response.data.data?.phone || '-'
          this.form.email = response.data.data?.company_email || '-'
          this.form.client_name = ''
        }
      })
  }

  submit(): void {
    if (this.payload !== null) {
      RequestService.createRequest(this.payload)
        .then(() => {
          this.$router.push({ name: GeneralRoutes.requestByStatus })
        })
        .catch((error: any) => (this.error = error))
    }
  }
}
