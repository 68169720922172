





































































import { Component, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { GeneralRoutes } from '@/router/routes/general'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import IRequestItem, { IRequestItemCompact } from '@/modules/requests/interfaces/request-item.interface';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import http from '@/shared/helpers/http';
import IResponse, { IMeta } from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import IRequestResponse from '@/modules/requests/interfaces/request-response.interface';
import Pagination from '@/shared/components/data-table/Pagination.vue';
import SelectOption from '@/shared/classes/components/form/select-option';
import { PerPageOptions } from '@/shared/configs/per-page-options';
import { tableCount } from '@/helpers/tableCount';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

@Component({
  components: { Pagination, TableLoader, DataTable },
  methods: { __ }
})
export default class Dashboard extends StickyTableHeader {
  @Prop() heading!: string
  @Prop() filter!: string
  @Prop() tableId!: string
  @Prop() hidePerPage!: boolean

  loading = true
  GeneralRoutes = GeneralRoutes
  inProgressItems: IRequestItemCompact[] = []
  meta: IMeta | undefined | null = null
  perPage = 10
  perPageOptions: SelectOption[] = PerPageOptions
  tableKey = 0

  requestFields: TableField[] = [
    { key: 'name', label: __('views.requests.form.client'), sortable: true },
    { key: 'description', label: __('views.requests.form.description'), sortable: true },
    { key: 'manager', label: __('views.requests.form.manager'), sortable: true },
    { key: 'status', label: __('views.requests.form.status'), sortable: true }
  ]

  created(): void {
    tableCount.getRowCount(this.tableId).then((perPage: any) => {
      if (!this.hidePerPage) {
        this.perPage = perPage
      }
      this.fetchRequests()
    })
  }

  perPageChange(): void {
    tableCount.setRowCount(this.tableId, this.perPage)
    this.fetchRequests()
  }

  fetchRequests(page: string | number = 1): void {
    this.loading = true
    http
      .get('/requests', { params: { per_page: this.perPage, page, 'filter[in.status]': this.filter, sort: '-created_at' } })
      .then((response: IResponse<IModelResponse>) => {
        this.meta = response.data.meta
        const processedItems: IRequestItemCompact[] = []
        response.data.data.forEach((item: IRequestResponse) => {
          processedItems.push({
            id: item.id,
            name: item.client_name,
            description: this.trimText(item.description),
            statusTitle: item.status_title,
            status: item.status,
            manager: item.manager?.full_name || ''
          })
        })
        this.inProgressItems = processedItems
      })
      .finally(() => {
        this.loading = false
        this.setStickyHeader()
        this.tableKey++
      })
  }

  rowClick(item: IRequestItem): void {
    this.$router.push({ name: GeneralRoutes.requestInner, params: { id: item.id.toString() } })
  }

  trimText(text: string): string {
    text = text
      .replace(/(<([^>]+)>)/gi, '').replace(new RegExp('&nbsp;', 'g'), ' ')
      .replace(new RegExp('&scaron;', 'g'), 'š')
      .replace(new RegExp('&Scaron;', 'g'), 'Š')
    if (text.length > 100) {
      return text.slice(0, 96) + '...'
    }
    return text
  }
}

interface TableField {
  key: string
  label: string
  sortable: boolean
}
